import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import PageWrapper from "../components/PageWrapper";
import { Section, Title, Text, Box, List } from "../components/Core";

const Privacy = () => {
  return (
    <>
      <PageWrapper footerDark>
        <Section>
          <div className="pt-5"></div>
          <Container>
            <Row className="justify-content-center">
              <Col lg="9" xl="8">
                <Title variant="hero" className="text-center">
                  Privacy
                </Title>
                <Text variant="small">
                  <Title variant="card" fontSize="2rem" mb="10px">What do we use your information for?</Title>
                  <p>Any of the information we collect from you may be used in one of the following
								ways: </p>
                  <List>
                    <li> To personalize your experience (your information helps us to better respond to your individual needs)</li>
                    <li>To improve our website (we continually strive to improve our website offerings based on the information and feedback we receive from you)</li>
                    <li>To improve customer service (your information helps us to more effectively respond to your customer service requests and support needs)</li>
                    <li>To process transactions
                    </li>
                    <li>To administer a contest, promotion, survey or other site feature<br /></li>
                    <li> To send periodic emails - The email address you provide for order processing, will only be used to send you
                    information and updates pertaining to your order.
                    </li>
                  </List>
                                Note: If at any time you would like to unsubscribe from receiving future
								emails, we include detailed unsubscribe instructions at the bottom of each email.<br />

                  <Title variant="card" fontSize="2rem" mt="30px" mb="10px">We will never:</Title>
                  <Text variant="small">Sell, exchange transfer or give your information, whether public or private, to any other company for any reason whatsoever, without your
                  consent, other than for the express purpose of delivering the purchased product or
                  service requested.
                  </Text>

                </Text>

                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    How do we protect your information?
                  </Title>
                  <Text variant="small">
                    We implement a variety of security measures to maintain the safety of your personal information when you enter, submit, or access your personal information.
                    We offer the use of a secure server. All supplied sensitive/credit information is transmitted via Secure Socket Layer (SSL) technology and then encrypted into our Payment gateway providers database only to be accessible by those authorized with special access rights to such systems, and are required to keep the information confidential.
                  </Text>
                  <Text variant="small">
                    After a transaction, your private information (credit cards, social security numbers, financials, etc.) will not be stored on our servers.
                </Text>
                </Box>

                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Do we use cookies?
                  </Title>
                  <Text variant="small">
                    Yes (Cookies are small files that a site or its service provider transfers to your computers hard drive through your Web browser (if you allow) that enables the sites or service providers systems to recognize your browser and capture and remember certain information
                    We use cookies to understand and save your preferences for future visits.
                </Text>
                </Box>

                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Do we disclose any information to outside parties?
                  </Title>
                  <Text variant="small">
                    We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information. This does not include trusted third parties who assist us in operating our website, conducting our business, or servicing you, so long as those parties agree to keep this information confidential. We may also release your information when we believe release is appropriate to comply with the law, enforce our site policies, or protect ours or others rights, property, or safety. However, non-personally identifiable visitor information may be provided to other parties for marketing, advertising, or other uses.
                  </Text>
                </Box>

                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Childrens Online Privacy Protection Act Compliance
                  </Title>
                  <Text variant="small">
                    We are in compliance with the requirements of COPPA (Childrens Online Privacy Protection Act), we do not collect any information from anyone under 13 years of age. Our website, products and services are all directed to people who are at least 13 years old or older.
                  </Text>
                </Box>
                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Terms and Conditions
                  </Title>
                  <Text variant="small">
                    Please also visit our Terms and Conditions section establishing the use, disclaimers, and limitations of liability governing the use of our website at <a href="/terms-conditions">http://www.hotsnail.com.au/terms-conditions</a>
                  </Text>
                </Box>
                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Your Consent
                  </Title>
                  <Text variant="small">
                    By using our site, you consent to our online privacy policy.
                  </Text>
                </Box>

                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Changes to our Privacy Policy
                  </Title>
                  <Text variant="small">
                    If we decide to change our privacy policy, we will send an email notifying you of any changes.
                  </Text>
                </Box>

                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Law Enforcement Agencies
                  </Title>
                  <Text variant="small">
                    HotSnail will comply with all government investigations into clients without the need for a warrant.
                  </Text>
                </Box>

                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Contacting Us
                  </Title>
                  <Text variant="small">
                    If there are any questions regarding this privacy policy you may contact us using the information below.<br></br>
                    <br></br>
                    http://www.hotsnail.com.au<br></br>
                    PO Box 16<br></br>
                    Nobby Beach, Qld 4218<br></br>
                    Australia<br></br>
                    mail@hotsnail.com.au<br></br>
                  </Text>
                </Box>
              </Col>
            </Row>
          </Container>
        </Section>
      </PageWrapper>
    </>
  );
};
export default Privacy;
